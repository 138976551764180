.file-upload {
    .imagepreview {
        object-fit: contain;
    }

    .d-example {
        max-width: 255px;
        max-height: 200px;
        padding: 5px;
        border: 2px dashed #e7e7ee;
        // margin-top: 20px;
    }

    .preview img {
        max-width: 255px;
        max-height: 200px;
        padding: 5px;
        border: 2px dashed #e7e7ee;
        // margin-top: 20px;
    }
}
