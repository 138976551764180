.agreement {
    ol {
        counter-reset: item;
    }

    li {
        display: block;
    }

    // li:before {
    //     // margin-left: 12px;
    //     content: counters(item, ".") " ";
    //     counter-increment: item;
    // }

    li.nostyle {
        list-style-type: none;
    }
}
